import {Form, withFormik} from "formik";
import React, {useContext, useEffect, useState} from "react";
import {CompanyInfoFormFields} from "./CompanyInfoFormFields";

import {ColoredLine, ErrorText, PanelHeader} from '../HelperComponents'
import {defaultLabelFont, emptyCheck, ProfileDataRowFlex, StyledText, TextRow} from "../ProfileControls";
import {Box, TooltipComponent} from "@qc-modules/components";
import {defaultValueFont, isValidImpersonation} from "../../util/helper";
import {checkTokenValidity, checkAcrValues} from "../../../services/authService";
import {profileConstants} from "../../util/profileConstants2";
import {trackCtaEvent} from "../../../utils/Utils";
import AppContext from "../../../context/AppContext";
import useProfile from "../../../hooks/useProfile2";


const EditCompanyInfoBasicForm = ({...props}) => {
    const profileStore = useProfile();
    const userData = profileStore.userData;
    const {appStore} = useContext(AppContext);
    const impersonationStatus = appStore.impersonationStatus
    const previousPage = '/login';
    const [isOrgChange, setIsOrgChange] = useState(false);

    useEffect(() => {
        if (props.status.isEditMode && userData !== null && userData !== undefined) {
            let visitor = {
                'country_code': userData.country_code,
                'org_id': userData.org.orgId,
                'org_name': userData.org.name,
                'qcguid': userData.qcGuid,
                'user_name': userData.username,
                'loggedIn': "logged_in",
                'user_domain': userData.username && userData.username.indexOf("@") > -1 ? userData.username.slice(userData.username.indexOf("@") + 1) : 'qualcomm.com'

            }
            if (isValidImpersonation(impersonationStatus)) {
                visitor['impersonating'] = true
                visitor['impersonator'] = impersonationStatus.impersonator
            }

            // trackPageLoadEvent('profile', 'CompanyInfo', previousPage, visitor, {})
            window.pageChange('profile', 'CompanyInfo', previousPage, visitor, {})
        }
    }, [props.status.isEditMode, userData, impersonationStatus]);

    return (
        <>
        <Form onSubmit={props.handleSubmit} className="Bizible-Exclude">
                <PanelHeader
                    name={'companyInfo'}
                    isLaptop={props.isLaptop}
                    label={'Company information'}
                    isEdit={props.status.isEditMode}
                    disableEdit={props.disableEdit}
                    hideControls={props.isStreamlinedUser || props.isInternalUser ?true:false}
                    handleEditButton={() => {
                        checkAcrValues();
                        checkTokenValidity();
                        props.setStatus({isEditMode: true});
                        props.toggleOtherSectionsEditBtn();
                        trackCtaEvent('CompanyInfo', 'Edit')
                    }}
                    handleCancelButton={() => {
                        props.toggleOtherSectionsEditBtn();
                        props.resetForm();
                        props.setStatus({isEditMode: false});
                        setIsOrgChange(false);
                        trackCtaEvent('CompanyInfo', 'Cancel')
                    }}/>


                {/*API Error Messages*/}
                {props.status && props.status.isEditMode && props.status.isError && props.status.errorMsg &&
                    props.status.errorMsg.map((err, key) => (
                        <ErrorText key={key} w={100}>{err}</ErrorText>)
                    )}

                <ColoredLine/>
                {!props.isStreamlinedUser && !props.isInternalUser &&
                <CompanyInfoFormFields isFormSubmitted={props.submitCount > 0} {...props}
                                       isEditMode={props.status.isEditMode} isOrgChange={isOrgChange}
                                       setIsOrgChange={setIsOrgChange}/>}

            </Form>

            {/*tool tip wont work inside form tag .So keeping it outside*/}

            <ProfileDataRowFlex>
                <Box width={0.45}>
                    <TextRow
                        align={'left'} {...defaultLabelFont}
                    >Customer ID</TextRow>
                </Box>
                <Box direction={'column'} align={'baseline'}
                     width={0.55}>
                    {
                        <StyledText
                            data-testid={`customerId-label`} {...defaultValueFont}>
                            {
                                (props.isLightUser) ?
                                    (<Box> You do not have a
                                        Customer ID
                                        {props.status.isEditMode &&
                                            <TooltipComponent
                                                isLarge={true}>
                                                {'A Qualcomm Customer ID is a unique identifier we use to align our systems to your company. If you have interacted with our Sales or Support teams in the past, you are likely to have a Qualcomm Customer ID.'}
                                            </TooltipComponent>
                                        }
                                    </Box>)
                                    : (emptyCheck(props.values.customerId))
                            }
                        </StyledText>}
                </Box>
            </ProfileDataRowFlex>
            <ProfileDataRowFlex>
                < Box width={0.45}>
                    <TextRow align={'left'} {...defaultLabelFont}
                    >Export ID </TextRow>
                </Box>
                <Box direction={'column'} align={'baseline'} width={0.55}>
                    {
                        <StyledText
                            data-testid={`orgExportId-label`} {...defaultValueFont}>

                            {
                                (props.isLightUser) ?
                                    (<Box> You do not have a Export ID
                                        {props.status.isEditMode &&
                                            <TooltipComponent
                                                isLarge={true}>
                                                {'A Qualcomm Export ID is a unique identifier we use to align our systems to your company. If you have interacted with our Sales or Support teams in the past, you are likely to have a Qualcomm Export ID.'}
                                            </TooltipComponent>
                                        }
                                    </Box>)
                                    : (emptyCheck(props.values.orgExportId))
                            }


                        </StyledText>}
                </Box>
            </ProfileDataRowFlex>
        </>
    )
};

export const CompanyInfoForm = withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({formValues}) => {
        return formValues;
    },
    mapPropsToStatus: ({isLaptop, isInternalUser}) => {
        if (isInternalUser) {
            return {isEditMode: false}
        }
        return {isEditMode: !isLaptop}
    },
    handleSubmit: async (values, {props, setStatus}) => {
        const payload = {
            addressLine1: values.addressLine1,
            addressLine2: values.addressLine2,
            country: values.country,
            city: values.city,
            state: values.state,
            postalCode: values.postalCode,
            orgExportId: values.orgExportId
        };
        if(values.orgExportId === 8888){
            console.log('Streamlined Users selecting Org. User has to be email validated or had DPL Approved');
            setStatus({isEditMode: false,isError:true,errorMsg:'Streamlined Users selecting Org. User has to be email validated or had DPL Approved'});
            return false
        }
        if (!props.countryStateMap.find(obj => obj.id === values.country)) {
            payload.province = "";
            payload.state = "";
        }
        const resp = await props.saveData(payload, profileConstants.COMPANY_INFO_SECTION_ADOBE_KEY);

        if (resp.isError) {
            setStatus({isEditMode: true, isError: true, errorMsg: resp.errorMsg})
        } else {
            setStatus({isEditMode: false});
            props.toggleOtherSectionsEditBtn();
        }
    }
})(EditCompanyInfoBasicForm);
